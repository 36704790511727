 require('../form');require('./forgot-password');
riot.tag2('signup', '<div if="{error}" class="alert alert-danger">{error}</div> <form width1="10" width2="2" class="form-horizontal"> <forminput name="name" label="Name" onupdate="{handleUpdate}" value="{user.name}" width1="2" width2="10"></forminput> <forminput name="email" label="Email" onupdate="{handleUpdate}" value="{user.email}" width1="2" width2="10"></forminput> <forminput name="company" label="Company" onupdate="{handleUpdate}" value="{user.company}" width1="2" width2="10"></forminput> <forminput name="password" label="Password" onupdate="{handleUpdate}" value="{user.password}" type="password" width1="2" width2="10"></forminput> <formsubmit if="{!opts.hidebutton}" onclick="{signup}" type="submit" width1="10" width2="2">Sign Up</formsubmit> </form>', '', '', function(opts) {
var loginApi, resource;

resource = require('../../utils/apiResource.coffee');

loginApi = new resource(opts.src || '/duocms/api/register', true);

this.on('mount', function() {
  return this.root.trigger = this.trigger;
});

this.on('reset', (function(_this) {
  return function() {
    return _this.reset();
  };
})(this));

this.on('signup', (function(_this) {
  return function() {
    return _this.signup();
  };
})(this));

this.user = {
  email: '',
  password: '',
  name: '',
  company: ''
};

this.reset = (function(_this) {
  return function() {
    _this.user.email = '';
    _this.user.password = '';
    _this.user.name = '';
    _this.user.company = '';
    return _this.update();
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.signup = (function(_this) {
  return function() {
    _this.error = null;
    return loginApi.save(_this.user, function(user, error) {
      if (error != null) {
        _this.error = error.message;
        return _this.update();
      } else {
        if ((opts.onsignup != null) && typeof opts.onsignup === "function") {
          _this.opts.onsignup(user);
        }
        if (opts.redirect != null) {
          return window.location.href = opts.redirect;
        }
      }
    });
  };
})(this);
});
riot.tag2('signup-modal', '<yield></yield> <modal name="signupmodal" onclose="{close}" title="Sign Up" savetext="Sign Up" onsave="{handlesignup}" esckey="{true}"> <signup onsignup="{parent.signedin}" hidebutton="{true}" hidesignup="{true}"></signup> </modal>', 'signup-modal { cursor: pointer; }', 'onclick="{show}"', function(opts) {
this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.signupmodal = this.tags.signupmodal;
  this.signup = this.signupmodal.tags.signup;
  return this.update();
});

this.on('show', (function(_this) {
  return function() {
    return _this.show();
  };
})(this));

this.show = (function(_this) {
  return function() {
    document.addEventListener('keydown', _this.keydown);
    return _this.signupmodal.show();
  };
})(this);

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 13) {
      return _this.handlesignup();
    }
  };
})(this);

this.close = (function(_this) {
  return function() {
    document.removeEventListener('keydown', _this.keydown);
    return _this.signup.trigger('reset');
  };
})(this);

this.signedin = (function(_this) {
  return function(user) {
    _this.signupmodal.close();
    if ((opts.onsignup != null) && typeof opts.onsignup === "function") {
      return opts.onsignup(user);
    }
  };
})(this);

this.handlesignup = (function(_this) {
  return function() {
    return _this.signup.trigger('signup');
  };
})(this);
});