
riot.tag2('slideshow', '<yield></yield>', 'slideshow.default .carousel { height: auto; } slideshow.default .carousel .carousel-control { cursor: pointer; } slideshow.fade .carousel .carousel-inner .item { -webkit-transition: opacity 2s; -moz-transition: opacity 2s; -o-transition: opacity 2s; -ms-transition: opacity 2s; transition: opacity 2s; opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } slideshow.fade .carousel .carousel-inner .item.active.left { opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter: alpha(opacity=0); } slideshow.fade .carousel .carousel-inner .item.active, slideshow.fade .carousel .carousel-inner .item.next.left { -webkit-transition: opacity 1s; -moz-transition: opacity 1s; -o-transition: opacity 1s; -ms-transition: opacity 1s; transition: opacity 1s; opacity: 1; -ms-filter: none; filter: none; } slideshow.fade .carousel .carousel-inner .item.next, slideshow.fade .carousel .carousel-inner .item.active.left { left: 0; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }', '', function(opts) {
this.slide_index = 0;

this.slideclass = ['active'];

this.total = 0;

this.animating = false;

this.paused = false;

this.on('mount', function() {
  this.total = this.root.querySelectorAll('.item').length;
  this.update();
  return this.autoplay();
});

this.autoplay = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    if (_this.autoplayInterval != null) {
      clearInterval(_this.autoplayInterval);
    }
    return _this.autoplayInterval = setInterval(function() {
      return _this.next();
    }, _this.opts.autoplay);
  };
})(this);

this.pause = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    if (_this.autoplayInterval != null) {
      return clearInterval(_this.autoplayInterval);
    }
  };
})(this);

this.unpause = (function(_this) {
  return function() {
    if (_this.opts.autoplay == null) {
      return;
    }
    return _this.autoplay();
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index++;
    if (_this.slide_index >= _this.total) {
      _this.slide_index = 0;
    }
    if (_this.total > 1) {
      _this.slidenext();
    }
    return _this.autoplay();
  };
})(this);

this.slidenext = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active left';
    _this.slideclass[_this.slide_index] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'next left';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.animating = false;
      return _this.update();
    }, 600);
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index--;
    if (_this.slide_index < 0) {
      _this.slide_index = _this.total - 1;
    }
    if (_this.total > 1) {
      _this.slideprev();
    }
    return _this.autoplay();
  };
})(this);

this.slideprev = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active right';
    _this.slideclass[_this.slide_index] = 'prev';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'prev right';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.slide_index] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.animating = false;
      return _this.update();
    }, 600);
  };
})(this);

this.goto = (function(_this) {
  return function(idx) {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.slide_index;
    _this.slide_index = idx;
    if (_this.slide_index < _this.previndex) {
      _this.slideprev();
    } else {
      _this.slidenext();
    }
    _this.update();
    return _this.autoplay();
  };
})(this);
});