 require('./dateformat')

riot.tag2('twitter-script', '', '', '', function(opts) {
    !function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)){
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }(document, 'script', 'twitter-wjs');

});
riot.tag2('twitter', '<twitter-script></twitter-script><a href="{opts.url}" data-chrome="{opts.chrome}" data-widget-id="{opts.widgetid}" height="{opts.height}" class="twitter-timeline">Tweets by {opts.handle}</a>', '', '', function(opts) {
});
riot.tag2('twitter-list', '<virtual each="{tweet,i in tweets}"><yield></yield></virtual>', '', '', function(opts) {
var resource;

resource = require('../utils/apiResource.coffee');

this.on('mount', function() {
  var params;
  params = opts.count ? '?count=' + opts.count : '';
  this.twitterApi = new resource((opts.src || '/duocms/api/tweets') + params);
  return this.twitterApi.get((function(_this) {
    return function(data) {
      return _this.onload(data);
    };
  })(this));
});

this.onload = (function(_this) {
  return function(data) {
    _this.tweets = data.map(function(tweet) {
      var ref;
      if ((ref = tweet.urls) != null) {
        ref.map(function(url) {
          return tweet.text = tweet.text.replace(url.url, '<a href="' + url.url + '">' + url.url + '</a>');
        });
      }
      tweet.created_at = new Date(tweet.created_at);
      return tweet;
    });
    if (opts.loaded) {
      opts.loaded(_this.tweets);
    }
    return _this.update();
  };
})(this);
});
riot.tag2('twitter-feed', '<div class="carousel slide"> <ol if="{opts.showDots}" class="carousel-indicators"> <li each="{tweet,i in tweets}" onclick="{parent.goto}" class="dot {active:i==parent.active}"></li> </ol> <div riot-tag="twitter-list" riot-src="{opts.src}" count="{opts.count}" loaded="{loaded}" class="carousel-inner"> <div class="item {parent.parent.slideclass[i]}"><i class="fa fa-twitter"></i> <p riot-tag="raw" content="{tweet.text}"></p> <p class="tweetdate">Posted on <dateformat date="{tweet.created_at}" format="dd MMMM yyyy"> </dateformat> </p> </div> </div> </div>', 'twitter-feed { display: block; position: relative; } twitter-feed .carousel-inner .item { padding-bottom: 40px; } @media screen and (min-width: 768px) { twitter-feed .carousel-indicators { bottom: 0px; } }', '', function(opts) {
this.interval = null;

this.animating = false;

this.slideclass = ['active'];

this.loaded = (function(_this) {
  return function(tweets) {
    _this.tweets = tweets;
    _this.active = 0;
    _this.total = _this.tweets.length;
    if (_this.opts.autoplay != null) {
      _this.interval = setInterval(function() {
        return _this.next();
      }, _this.opts.autoplay);
    }
    return _this.update();
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active++;
    if (_this.active >= _this.total) {
      _this.active = 0;
    }
    return _this.slidenext();
  };
})(this);

this.slidenext = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active left';
    _this.slideclass[_this.active] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'next left';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active--;
    if (_this.active < 0) {
      _this.active = _this.total - 1;
    }
    return _this.slideprev();
  };
})(this);

this.slideprev = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active right';
    _this.slideclass[_this.active] = 'prev';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'prev right';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.goto = (function(_this) {
  return function(e) {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active = e.item.i;
    if (_this.active < _this.previndex) {
      _this.slideprev();
    } else {
      _this.slidenext();
    }
    return _this.update();
  };
})(this);
});