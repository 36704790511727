
riot.tag2('pagination', '<ul if="{pages.length &gt; 1}" class="pagination"> <li class="prev {\'disabled\':currentpage==0}"><a onclick="{prev}">&laquo;</a></li> <li each="{page in pages}" class="{\'active\':currentpage == page}"><a onclick="{setPage.bind(null,page)}">{page+1}</a></li> <li class="next {\'disabled\':currentpage+2&gt;total}"><a onclick="{next}">&raquo;</a></li> </ul>', '', '', function(opts) {
this.on('mount', function() {
  this.perpage = +opts.perpage || 10;
  this.max = +opts.max || 10;
  this.pages = [];
  this.currentpage = 0;
  return this.data = [];
});

this.on('update', function() {
  return this.setPage();
});

this.next = (function(_this) {
  return function() {
    if (_this.currentpage < _this.total - 1) {
      _this.currentpage++;
      return _this.update();
    }
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.currentpage > 0) {
      _this.currentpage--;
      return _this.update();
    }
  };
})(this);

this.setPage = (function(_this) {
  return function(pagenum) {
    var i, ref, ref1, ref2, results;
    if (pagenum != null) {
      _this.currentpage = pagenum;
    }
    if ((typeof opts !== "undefined" && opts !== null ? (ref = opts.data) != null ? ref.length : void 0 : void 0) != null) {
      _this.total = Math.ceil(opts.data.length / _this.perpage);
      _this.start = _this.perpage * _this.currentpage;
      _this.end = _this.start + _this.perpage;
      if (opts.data && _this.end > opts.data.length) {
        _this.end = opts.data.length;
      }
      if (_this.start < 0 || _this.start > opts.data.length) {
        _this.start = 0;
      }
      _this.data = opts.data.slice(_this.start, _this.end);
    } else {
      _this.total = 0;
    }
    if (_this.currentpage > (_this.max / 2) && _this.max < _this.total) {
      _this.first = _this.currentpage - _this.max / 2;
    } else {
      _this.first = 0;
    }
    if (_this.first + _this.max > _this.total) {
      _this.first = _this.total - _this.max;
      if (_this.first < 0) {
        _this.first = 0;
      }
    }
    if (_this.data) {
      _this.pages = (function() {
        results = [];
        for (var i = ref1 = _this.first, ref2 = _this.total; ref1 <= ref2 ? i < ref2 : i > ref2; ref1 <= ref2 ? i++ : i--){ results.push(i); }
        return results;
      }).apply(this);
    }
    if (opts.updatepages != null) {
      opts.updatepages(_this.data);
    }
    return _this.update();
  };
})(this);
});