 require('../form');require('../i18n');
riot.tag2('forgot-password', '<form class="form-horizontal"> <p if="{opts.customMessage}"> <i18n content="{opts.customMessage}"></i18n> </p> <p> <i18n>Enter your email below, if you have an account you will be sent an email containing a password reset link.</i18n> </p> <forminput name="email" label="Email" onupdate="{handleUpdate}" value="{user.email}" width1="2" width2="10"></forminput> <button if="{!opts.hidebutton}" onclick="{submit}" __disabled="{sending}" class="btn btn-primary {\'disabled\':sending}">Forgotten Password</button> <div if="{sending}" class="alert alert-info"> <p> <i18n>Sending </i18n> </p> </div> </form>', '', '', function(opts) {
var api, resource;

resource = require('../../utils/apiResource.coffee');

api = new resource(opts.src || '/duocms/api/forgot_password');

this.user = {
  email: this.opts.email
};

this.sending = false;

this.on('mount', function() {
  return this.root.trigger = this.trigger;
});

this.on('reset', (function(_this) {
  return function() {
    return _this.reset();
  };
})(this));

this.on('submit', (function(_this) {
  return function() {
    return _this.submit();
  };
})(this));

this.reset = (function(_this) {
  return function() {
    _this.user.email = '';
    return _this.update();
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.submit = (function(_this) {
  return function() {
    _this.sending = true;
    _this.update();
    return setTimeout(function() {
      return api.save(_this.user, function() {
        _this.sending = false;
        _this.update();
        if ((opts.onsubmit != null) && typeof opts.onsubmit === "function") {
          return _this.opts.onsubmit();
        }
      });
    }, 1000);
  };
})(this);
});
riot.tag2('forgot-password-modal', '<yield></yield> <modal name="forgotpasswordmodal" onclose="{close}" title="Forgotten Password" savetext="Request Password Reset" onsave="{handlerequest}" esckey="{true}"> <forgot-password hidebutton="{true}" onsubmit="{parent.submitted}" custom-message="{parent.opts.customMessage}"></forgot-password> </modal>', 'forgot-password-modal { cursor: pointer; }', 'onclick="{show}"', function(opts) {
this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.forgotpasswordmodal = this.tags.forgotpasswordmodal;
  this.forgotpassword = this.forgotpasswordmodal.tags['forgot-password'];
  return this.update();
});

this.on('show', (function(_this) {
  return function() {
    return _this.show();
  };
})(this));

this.show = (function(_this) {
  return function() {
    document.addEventListener('keydown', _this.keydown);
    return _this.forgotpasswordmodal.show();
  };
})(this);

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 13) {
      return _this.handlerequest();
    }
  };
})(this);

this.close = (function(_this) {
  return function() {
    document.removeEventListener('keydown', _this.keydown);
    return _this.forgotpassword.trigger('reset');
  };
})(this);

this.submitted = (function(_this) {
  return function() {
    _this.forgotpasswordmodal.close();
    if ((opts.onsubmit != null) && typeof opts.onsubmit === "function") {
      return opts.onsubmit();
    }
  };
})(this);

this.handlerequest = (function(_this) {
  return function() {
    return _this.forgotpassword.trigger('submit');
  };
})(this);
});